
import { Component, Vue } from 'vue-property-decorator';
import { getUserInfo } from '@/api/user';
import { getPointInfo } from '@/api/point';
import { UserModule } from '@/store/user';
import { getRedirectUrl, removeRedirectUrl } from '@/utils/cookies';

@Component({
  components: {
  },
})

export default class extends Vue {
  mounted() {
    this.getUserInfo();
  }

  created() {
    /* eslint-disable */
    this.lastLoginType = localStorage.getItem('lastLoginType');
    const { code, state, id_token } = (this.$route.query as any);
    if (code && state) {
      if (state === 'google') {
        UserModule.GoogleLogin(this.$route.query.code as string).then(async (res) => {
          if (res === 'login') {
            localStorage.setItem('lastLoginType', 'google');
            await removeRedirectUrl();
          }
        }).catch(() => {
          this.loading = false;
          this.$router.replace({ query: {} });
        });
      } else {
        UserModule.NaverLogin(this.$route.query.code as string).then(async (res) => {
          if (res === 'login') {
            localStorage.setItem('lastLoginType', 'naver');
            await removeRedirectUrl();
          }
        }).catch(() => {
          this.$router.replace({ query: {} });
          this.loading = false;
        });
      }
    } else if (code) {
      UserModule.KakaoLogin(this.$route.query.code as string).then(async (res) => {
        if (res === 'login') {
          localStorage.setItem('lastLoginType', 'kakao');
          await removeRedirectUrl();
        }
      }).catch(() => {
        this.$router.replace({ query: {} });
        this.loading = false;
      });
    } else {
      console.log(this.$route);
      if (this.$route.hash.includes('id_token')) {
        const idToken = this.$route.hash.substring(this.$route.hash.indexOf('id_token=') + 9);
        UserModule.AppleLogin(idToken).then(async (res) => {
          if (res === 'login') {
            localStorage.setItem('lastLoginType', 'apple');
            const redirectUrl = getRedirectUrl();
            if (redirectUrl) this.$router.push({ path: redirectUrl });
            await removeRedirectUrl();
          }
        }).catch(() => {
          this.$router.replace({ query: {} });
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    }
    /* eslint-enable */
  }

  private loading = true;

  private lastLoginType: string | null = '';

  private goBack() {
    this.$router.go(-1);
  }

  get actualName() {
    return UserModule.actualName;
  }

  private handleClickLogout() {
    UserModule.LogOut();
    this.$router.go(0);
  }

  private optionInfo : { optionImgUrl: String, optionName: String } = { optionImgUrl: '', optionName: '' };

  private optionList : { optionImgUrl: String, optionName: String }[] = [];

  // 포인트 기준
  get PointOptionList() {
    if (this.levelOptionList.length === 0) {
      return []; // 빈 배열 반환
    }
    return this.levelOptionList;
  }

  private userInfo = {
    actualName: '',
    concatNumber: '',
    point: 0,
  }

  // 포인트 옵션 기본값 세팅
  private pointOption = {
    level: 0,
    optionName: '',
    optionPoint: 0,
  }

  private levelOptionList: { optionPoint: number, optionName: string, imgUrl : string }[] = [];// 포인트 옵션

  private myLevelName = '';

  private progress = 0;

  private nextLevelName = '이웃군민';

  private nextLevelPoint = 0;

  private needLevelPoint = 0;

  private dialogNotifyVisible = false;

  private maxLevelCnt = 0;

  // 포인트 옵션
  private getPointInfo() {
    getPointInfo().then((res) => {
      this.levelOptionList = res.data;
      this.mylevel();
    });
  }

  // 유저정보
  private getUserInfo() {
    getUserInfo().then((res) => {
      this.userInfo = res.data;
      this.getPointInfo();
    });
  }

  private nextLevelIdx = 0;

  /* 마이 레벨 */
  private mylevel() {
    this.maxLevelCnt = this.levelOptionList.length;
    for (let i = this.maxLevelCnt - 1; i >= 0; i -= 1) {
      const level: { optionPoint: number, optionName: string, imgUrl : string } = this.levelOptionList[i];
      if (Number(this.userInfo.point) >= Number(level.optionPoint)) {
        this.optionInfo.optionName = level.optionName;
        this.optionInfo.optionImgUrl = level.imgUrl;
        // 400,000이상일때 if 처리
        if (i < this.maxLevelCnt - 1) {
          this.nextLevelName = this.levelOptionList[i + 1].optionName;
          this.nextLevelPoint = this.levelOptionList[i + 1].optionPoint;
          this.needLevelPoint = this.levelOptionList[i + 1].optionPoint - this.userInfo.point;
        }
        break;
      }
    }
    this.progressChange();
    this.$emit('customEvent', this.optionInfo);
  }

  // 계산식
  private progressCalculation(start : number, end: number, isPoint: number) {
    return (33 * ((isPoint - start) / (end - start)));
  }

  private progressChange() {
    const myPoint = this.userInfo.point;
    const levelOptionstart = this.levelOptionList[0].optionPoint; // 0
    const levelOptionstart1 = this.levelOptionList[1].optionPoint; // 100,000
    const levelOptionstart2 = this.levelOptionList[2].optionPoint; // 200,000
    const levelOptionEnd = this.levelOptionList[3].optionPoint; // 400,000
    if (myPoint >= levelOptionstart && myPoint < levelOptionstart1) { // 이웃
      this.progress = this.progressCalculation(levelOptionstart, levelOptionstart1, myPoint);
    } else if (myPoint >= levelOptionstart1 && myPoint < levelOptionstart2) { // 단짝
      this.progress = 33 + this.progressCalculation(levelOptionstart1, levelOptionstart2, myPoint);
    } else if (myPoint >= levelOptionstart2 && myPoint <= levelOptionEnd) { // 명예
      this.progress = 66 + this.progressCalculation(levelOptionstart2, levelOptionEnd, myPoint);
    } else { // 남해
      this.progress = 100;
    }
  }
}
