
import { Component, Vue } from 'vue-property-decorator';
import { UserModule } from '@/store/user';
import { getPointHistoryList } from '@/api/pointHistory';
import InfiniteLoading from 'vue-infinite-loading';
import MypageProfile from './components/profile.vue';

@Component({
  components: {
    InfiniteLoading,
    MypageProfile,
  },
})

export default class extends Vue {
  mounted() {
    this.getPointHistoryList();
  }

  get actualName() {
    return UserModule.actualName;
  }

  private loading = true;

  private listQuery = {
    page: 0,
    size: 10,
    type: 'all',
  }

  private isNoMore = false;

  private pointHistoryList = [];

  private getPointHistoryList() {
    this.loading = true;
    getPointHistoryList(this.listQuery).then((res) => {
      this.loading = false;
      this.pointHistoryList = this.pointHistoryList.concat(res.data.content);
      this.isNoMore = res.data.last;
    });
  }

  private getMorePointHistoryList() {
    this.listQuery.page += 1;
    this.getPointHistoryList();
  }

  private goBack() {
    this.$router.go(-1);
  }

  private handleChangeType() {
    this.listQuery.page = 0;
    this.pointHistoryList = [];
    this.isNoMore = false;
    this.getPointHistoryList();
  }
}
